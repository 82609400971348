<template>

    <div class="mobile-mega-menu">
      <a href="javascript:void(0)" class="hidden mobile-menu-opener" @click="toggleMenu"></a>
        <div class="brands" v-show="currentState === 'brands'">
          <div class="mobile-link" @click="toggleMenu()">
                Автомобили
                <img class="shevron-down" :class="show_menu ? 'rotated' : ''" :src="'/img/shevron-down.svg'" alt="">
            </div>
            <div v-if="show_menu" class="brands-list-wrapper">
                <div class="brand-name" v-for="brand in brands" @click="selectBrand(brand.id)">
                    {{ brand.name }}
                    <img :src="'/img/shevron-right.svg'" alt="">
                </div>
            </div>
        </div>
        <div class="models" v-show="currentState === 'models'">
            <div class="cars-header">
                <img class="shevron-left" :src="'/img/shevron-left.svg'" alt="" @click="brand_id = null">
                Модель
            </div>
            <div class="models-list">
                <div class="model-wrapper" v-for="model in currentModels" @click="selectModel(model.id) " >
                    <div class="model" :class="model_id === model.id ? 'bg-grey' : ''" >
                        <img class="model-img" :src="model.photo" alt="">
                        <div class="model-text">
                            <span class="model-name-text">{{ model.name }}</span>
                            <img class="shevron-down" :src="'/img/shevron-down.svg'" alt="" :class="model_id === model.id ? 'rotated' : ''">
                        </div>
                    </div>
                    <div v-if="model_id === model.id" class="complectation-list">
                        <div class="version-wrapper" :class="version.id === version_id ? 'bg-grey' : ''" v-for="version in currentVersions" @click.stop="selectVersion(version.id)">
                            <div class="version-details">
                                <span class="version-name">{{ version.name }}</span>
                                <span class="version-price" v-if="version.price > 0">
                                    от {{ Intl.NumberFormat('ru-RU', {
                                       style: 'currency', currency: 'RUB',
                                       minimumFractionDigits: 0
                                       }).format(version.price) }}
                                </span>
                            </div>
                            <div class="buttons" v-show="version.id === version_id">
                              <a @click="showFrm()" href="javascript:void(0)" class="call-btn">Купить</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
          brands:[],
          brand_id: null,
          model_id: null,
          show_menu: false,
          version_id: null,
          options: {}
        }
    },
    created() {
      this.brands = window.gon.brands
      this.options = window.gon.options
    },
    methods: {
      showFrm() {
        document.querySelector('.mobile-menu').remove('opened')
        document.querySelector('.header-btn1').click()
      },
        selectBrand(id) {
            this.brand_id = id
            this.model_id = null
        },
        selectModel(id) {
            if ( this.model_id === id) {
                this.model_id = null
            } else {
                this.model_id = id
                this.version_id = 0
            }
        },
        toggleMenu() {
            this.show_menu = !this.show_menu
        },
        selectVersion(id) {
            this.version_id = id
        }

    },
    computed: {
      currentBrand() {
        const index = this.brands.findIndex((brand) => brand.id === this.brand_id)
        if (index > -1) {
          return this.brands[index]
        } else {
          return null
        }
      },
      currentModel() {
        if (this.currentBrand === null || this.model_id === null) {
          return null
        } else {
          const index = this.currentBrand.models.findIndex((model) => model.id === this.model_id)
          return this.currentBrand.models[index]
        }
      },
      currentModels() {
        if (this.brand_id !== null) {
          return this.currentBrand.models
        } else {
          return []
        }
      },
      currentVersions() {
        if (this.currentModel !== null) {
          return this.currentModel.versions
        } else {
          return []
        }
      },
      currentState() {
          if (this.brand_id !== null) {
              return 'models'
          } else {
              return 'brands'
          }
      }
    }
}
</script>

<style scoped>

.call-btn {
  text-decoration: none;

}

.mobile-link {
  padding-left: 20px;
}

.cars-header {
    font-size: 30px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #000;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 10px;
}

.shevron-down {
    width: 19px;
    transition: all 0.1s ease-in-out;
}

.rotated {
    rotate: 180deg;
    transition: all 0.2s ease-in-out;
}

.models {
    width: 100%;
    background-color: #ffffff;
}

.models-list {
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
}

.model-wrapper {
    width: 100%;
}

.model {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 30px 0 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.model-img {
  width: 185px;
  height: 90px;
  transform: scaleX(-1);
}

.model-text {
    padding: 35px 30px 35px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000;
    width: 100%;
}

.brands-list-wrapper {
    width: 100%;
}

.brand-name {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 22px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #000;
}

.complectation-list {
    width: 100%;

}
.version-wrapper {
    padding: 0 30px 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bg-grey {
    background: #f7f7f7;
}

.version-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px 30px;
}

.version-price {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: rgba(0, 0, 0, 0.5);
}

.version-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000;
}

@media (max-width: 432px) {

  .header-wrapper {
    padding: 20px 10px 0 10px;
  }
  .mobile-link {
    padding-left: 20px;
  }

  .model-img {
    padding-left: 10px;
  }

  .cars-header {
    padding-left: 10px;
  }
  .call-btn {
    width: 100px;
  }
  .chat-btn {
    width: 54px
  }
}
</style>