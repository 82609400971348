import SplitType from 'split-type'
import { gsap } from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

const run = function run(node) {
    if (document.querySelector(node)) {
        const text = new SplitType(node)
        gsap.fromTo(`${node} .char`, {
            color: "#DADADA"
        }, {
            duration: 1.5,
            color: "#222",
            stagger: .05,
            ease: "power4.out",
            scrollTrigger: {
                scrub: 2,
                trigger: `${node}`,
                start: "top 90%",
                end: "bottom 50%"
            }
        })
    }

}

const Painter = {
    start: run
}


export { Painter as default };